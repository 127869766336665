@import "@angular/material/theming";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css";
html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: Roboto, "Helvetica Neue", sans-serif !important;
    font-size: 13px;
}

.ag-theme-balham {
    --ag-header-foreground-color: #676a6e;
    --ag-header-background-color: #e4ecf0;
}

.ag-theme-balham .ag-details-row{
    padding : 5px !important
  }

::ng-deep .ag-theme-balham {
    font-family: Roboto, "Helvetica Neue", sans-serif !important;
    font-size: 13px;
}

a {
    text-decoration: none;
    cursor: pointer;
}

.btn-link {
    text-decoration: none;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    user-select: text;
}
.vpacs-btn-table {
    background: transparent linear-gradient(180deg, #ffffff 0%, #e9f0f3 100%) 0% 0% no-repeat padding-box;
    outline: none;
    border: 1px solid #c6d8e0;
    border-radius: 2px;
    font-size: 13px;
    font-weight: 500;
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.ag-theme-balham .cell-styling {
    background: transparent linear-gradient(180deg, #ffffff 0%, #e9f0f3 100%) 0% 0% no-repeat padding-box;
    outline: none;
    border: 1px solid #c6d8e0;
    border-radius: 1px !important;
}

.vpacs-btn-table-disabled {
    opacity: 0.5;
    pointer-events: none;
}

li {
    list-style-type: none;
}
.mat-dialog-container {
    background: transparent !important;
    box-shadow: none;
    overflow-y: hidden;
}


.ag-theme-balham .ag-header {
    font-weight: 500 !important;
    text-transform: uppercase;
    overflow-wrap: break-word !important;
}
.ag-theme-balham .ag-header-group-cell {
    font-weight: 500 !important;
    font-size: 11px;
}
.ag-theme-balham .ag-header-cell,.ag-theme-balham .ag-header-cell-label,.ag-theme-balham .ag-header-group-cell-label {
    white-space: normal !important;
    font-size: 11px;
    font-weight: 500 !important;
    display: flex;
    justify-content: center !important;
    align-items: center;
    text-align: center !important;
}
.ag-theme-balham .ag-header-cell-label .ag-header-cell-text {
    white-space: normal !important;
    text-align: center !important;
}


.ag-theme-balham .ag-header-row:not(:first-child) .ag-header-cell,
.ag-theme-balham .ag-header-row:not(:first-child) .ag-header-group-cell.ag-header-group-cell-with-group {
    background-color: #f4f0df;
}
.ag-theme-balham .tracking-comp{
 border: 0pt;
 background-color: #F4F0DF;
}

.ag-theme-balham .ag-header-row:not(:first-child) .ag-header-cell.no-border {
    border: 0;
}

.ag-theme-balham .ag-header-row:first-child .ag-header-cell.border {
    border: 0;
}


.ag-theme-balham .child-header-color {
    background-color: #e4ecf0 !important;
}
.ag-theme-balham .child-header-grid {
    background-color: #e4ecf0 !important;
    align-items: center;

}
.ag-theme-balham .child-header-color-warm {
    background-color: #FFFF66 !important;
    border-radius: 25px;
}
.ag-theme-balham .child-header-color-delay {
    background-color: #FF9966 !important;
    border-radius: 25px;
}
.ag-theme-balham .child-header-color-good {
    background-color: #66CCFF !important;
    border-radius: 25px;
}

.ag-theme-balham input[class^="ag-"]:not([type]),
.ag-theme-balham input[class^="ag-"][type="text"],
.ag-theme-balham input[class^="ag-"][type="number"],
.ag-theme-balham input[class^="ag-"][type="tel"],
.ag-theme-balham input[class^="ag-"][type="date"],
.ag-theme-balham input[class^="ag-"][type="datetime-local"],
.ag-theme-balham textarea[class^="ag-"] {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 1px 6px #00000029 !important;
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    height: 30px;
    min-width: 100%;
}
.ag-theme-balham .ag-icon-menu {
    display: none;
}
.ag-row {
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 11px;
    button {
        font-weight: 500;
    }
}

.ag-theme-balham .ag-row {
    border: none !important;
}

.ag-theme-balham .ag-layout-auto-height .ag-center-cols-clipper,
.ag-theme-balham .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-balham .ag-layout-print .ag-center-cols-clipper,
.ag-theme-balham .ag-layout-print .ag-center-cols-container {
    min-height: 180px !important;
}

.ag-row .ag-cell {
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-overflow: ellipsis !important;
    white-space: nowrap;
    padding: 1px 2px;
    height: 40px;
    user-select: text !important;
}

.ag-theme-balham .ag-tooltip {
    background-color: rgb(231, 231, 231);
    color: black;
    box-shadow: none !important;
}

.row-odd {
    background-color: #f7f9fb !important;
}
.row-even {
    background-color: white !important;
}
.row-pink {
    background-color: #ffc0cb !important;
}
.row-green {
    background-color: #c1ffc1 !important;
}
.row-bold {
    font-weight: bold !important;
}
.row-aqua {
    background-color: #c0fffc !important;
}
.row-yellow{
    background-color: #ffd700 !important;
}
.row-green-pur{
    background-color: #00ff00 !important;
}
.row-blue{
    background-color: #99bbff !important;
}

input[type="text"],
input[type="date"],
input[type="number"],
select,
.mat-sel {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 1px 6px #00000029;
    border: 1px solid #d7dce1;
    border-radius: 2px;
    font-size: 13px;
    padding: 0.4em;
    color: #676a6e;
    max-width: 180px;
    height: 30px;
}
input[type="text"]:focus,
input[type="date"]:focus,
input[type="number"]:focus,
select:focus {
    border: 1px solid #85a2af;
    outline: none;
    box-shadow: inset 0px 1px 6px #85a2af;
}

input[type="text"]:invalid,
input[type="date"]:invalid,
input[type="number"]:invalid,
select:invalid {
    border: 1px solid #ff0000;
    outline: none;
    box-shadow: none;
    background: #ffdfdf41 0% 0% no-repeat padding-box;
}
::-webkit-datetime-edit {
    text-transform: uppercase;
}
::-webkit-calendar-picker-indicator {
    background-image: url(../src/assets/images/Calender_svg.svg);
    width: 14px;
    height: 16px;
}
.disabled {
    opacity: 0.5;
    pointer-events: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    margin-right: 2px;
    padding: 3px;
}

input[type="text"]:disabled,
.mat-sel:disabled,
input[type="date"]:disabled,
select:disabled,
textarea:disabled {
    background-color: #9c9c9c25;
    pointer-events: none;
}

.breadcrumb-item + .breadcrumb-item {
    &:before {
        color: #676a6e;
        content: url(assets/images/Dropdown.svg) !important;
    }
}

input[type="checkbox"] {
    width: 40px !important;
    transform: scale(1.3);
    accent-color: rgb(0, 102, 255) !important;
}
.rotate {
    width: 30px;
    height: 30px;
    transform: rotate(90deg);
    margin-top: 20px;
}
.cell-span {
    background-color: white;
    color: black;
    position: absolute !important;
    top: 0px;
}

.mat-snack-bar-container {
    background: rgb(255, 255, 255);
    box-shadow: 5px 5px solid rgba(5, 5, 5, 0.205);
}

.mat-expansion-panel-body {
    padding: 0 24px 0px !important;
    margin: 0 !important;
}

.mat-expansion-panel-spacing {
    margin: 0px 0px !important;
}

.mat-expansion-panel-header-title,
.mat-expansion-panel-header-description {
    font-size: 12px !important;
    font-weight: 600 !important;
    align-items: center;
}

.vpacs-shadow {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15), 0 2px 7px 0 rgba(0, 0, 0, 0.15);
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    opacity: 0 !important;
    background-color: #00000029 !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: #2688da !important;
}

.mat-radio-container .mat-radio-outer-circle{
    border-color: #00000029 !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #2688da !important;
}


// PPF


.fs-13 {
    font-size: 13px;
}


.checkcontainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 13px;
}

.checkcontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px !important;
    z-index: 999;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 1px 3px #00000029;
    border: 1px solid #d7dce1;
    opacity: 1;
    border-radius: 2px;
}
.checkmark.disable{
    background: rgb(238, 238, 238) !important;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkcontainer .checkmark:after {
    left: 7px;
    top: 1px;
    width: 8px;
    height: 15px;
    border: solid #2386da;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkcontainer input:checked ~ .checkmark:after {
    display: block;
}



.form-control{
    border-radius: 2px;
}

button:disabled,
button[disabled] {
    cursor: no-drop;
}

.bg-danger{
    background-color: #ff8888 !important;
}